import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import * as style from './contact.module.css'

const Contact = () => {
  return (
    <Layout pageTitle="Contact">
      <Helmet>
        <meta name="description" content="Contact emails for Spiked Film" />
        <title>Contact | Spiked Film</title>
        <body className="bg-img contact-bg-img" />
      </Helmet>
      <div className={style.linkContainer}>
        <div className={style.mailLink}>
          <a href="mailto:bsoldwedel@westernnews.com">
            BSoldwedel@WesternNews.com
          </a>
        </div>
        <div className={style.mailLink}>
          <a href="mailto:veridicofilms@gmail.com">veridicofilms@gmail.com</a>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
